import React from 'react';
import CraigImg from './images/craig.png';

const Quote = () => {
  return (
    <div className="quote-component">
      <div className="columns">
        <div className="column quote-component__image">
          <img src={CraigImg} alt="Craig Reynolds" />
        </div>

        <div className="column quote-component__text is-size-4">
          <div className="quote-component__text__container">
            <p>
              I keep Portica open on my desktop all the time. It’s so much
              easier to pull up a drawing when a client or contractor calls,
              rather than searching through folders and subfolders.
            </p>

            <p>
              The same thing is true when I am at a job site. I can open any
              projects plans on my tablet or phone and zoom in on the area we
              are talking about. I don’t need printed sets anymore and I never
              show up empty handed.&rdquo;
            </p>

            <p className="quote-component__text__author">
              -Craig Reynolds, Landscape Architect and creator of Portica
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quote;

import React from 'react';
import { Link } from 'gatsby';

const Footer = () => {
  const appUrl = process.env.GATSBY_APP_URL;

  return (
    <div>
      <div className="footer has-text-centered">
        <div className="portica-wrapper">
          <div className="is-stretch">
            <p className="footer__text is-size-4">
              It's time to make the process of drawing delivery and iteration
              easy.
            </p>
            <p>
              <a
                href={`${appUrl}/users/create`}
                className="button is-primary is-uppercase"
              >
                Create my free account now
              </a>
            </p>
            <p className="is-size-7">No credit card required.</p>
          </div>
        </div>
      </div>
      <div className="footer-links has-text-centered">
        <p>
          <Link to="/privacy-policy">Privacy Policy</Link>{' '}
          <span className="has-text-dark">|</span>{' '}
          <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
        </p>
      </div>
    </div>
  );
};

export default Footer;

import React from 'react';
import compactModeImg from './images/compact-mode.png';
import progressModeImg from './images/progress-mode.png';
import projectPageImg from './images/project-page.png';
import fileSharingImg from './images/file-sharing.png';
import fileSharingMobileImg from './images/file-sharing-mobile.png';
import versionControlImg from './images/version-control.png';
import SvgArrow from '../svg-arrow';
import SvgArrowTxt from '../svg-arrow-txt';
import Feature from './feature';
import FeatureImage from './feature-image';
import FeatureText from './feature-text';
import FeatureTitle from './feature-title';

const ProductTour = () => {
  return (
    <div id="product-tour" className="product-tour">
      <div className="portica-wrapper is-widescreen">
        <p className="product-tour__intro is-size-4">
          It’s no secret that scheduling in the construction industry is like
          herding cats. So forget the Kanban and Gantt charts. Connect your
          clients and team while tracking real-time progress on Portica’s
          easy-to-use workspace and you will always have your finger on the
          pulse.
        </p>
      </div>

      <Feature>
        <FeatureText pos="left">
          <FeatureTitle>Project Dashboard</FeatureTitle>
          <p>
            Let’s you see and categorize the status of all your projects,
            at-a-glance. Know who’s running point, and which clients,
            contractors and consultants are signed in. It’s the big picture!
          </p>
        </FeatureText>

        <div className="column is-relative">
          <FeatureImage src={compactModeImg} alt="Compact Mode"></FeatureImage>
          <SvgArrow cls="arrow-compact-top">
            <svg
              width="150"
              height="250"
              version="1.1"
              viewBox="0 0 39.688 66.146"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g transform="translate(0 -230.85)">
                <g
                  className="svg-arrow__lines"
                  strokeLinecap="round"
                  strokeWidth=".79375"
                >
                  <path d="m21.383 233.08c36.909 26.118-1.5922 52.134-18.551 61.704" />
                  <path d="m4.3467 291.38-1.515 3.4019 3.6884 0.14166" />
                </g>
              </g>
            </svg>
            <SvgArrowTxt>
              Quickly see when updates happened and how long
              <br />
              each projects has been going. You’d be surprised!
            </SvgArrowTxt>
          </SvgArrow>

          <SvgArrow cls="arrow-compact-left">
            <svg
              width="250"
              height="250"
              version="1.1"
              viewBox="0 0 250 250"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                transform="matrix(.96593 -.25882 -.25882 -.96593 62.873 318.16)"
                className="svg-arrow__lines"
              >
                <g strokeLinecap="round" strokeWidth="3">
                  <g
                    transform="rotate(-90 115.33 213.61)"
                    strokeLinecap="round"
                    strokeWidth="3"
                  >
                    <path d="m241.31 76.866c73.302 139.82-96.953 224.21-158.97 259.2" />
                    <path d="m83.143 328.35-4.4103 9.9032 10.737 0.41238" />
                  </g>
                </g>
              </g>
            </svg>
            <SvgArrowTxt>
              Easily categorize and access all
              <br />
              project files, past-and-present
            </SvgArrowTxt>
          </SvgArrow>

          <SvgArrow cls="arrow-compact-bottom">
            <svg
              width="120"
              height="120"
              version="1.1"
              viewBox="0 0 120 120"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                transform="matrix(.29881 .64818 .64818 -.29881 -161.82 51.068)"
                className="svg-arrow__lines"
                strokeLinecap="round"
                strokeWidth="4.2032"
              >
                <path d="m146.14 187.67c92.504 94.8 76.618 152.02-27.352 179.09" />
                <path d="m125.6 358.33-6.8122 8.4332 10.269 3.164" />
              </g>
            </svg>
            <SvgArrowTxt>
              Make total progress part of
              <br />
              your daily scan
            </SvgArrowTxt>
          </SvgArrow>
        </div>
      </Feature>

      <Feature>
        <FeatureText pos="right" cls="is-hidden-tablet">
          <FeatureTitle>Progress Bars</FeatureTitle>
          <p>
            Track design and build progress separately so you can anticipate
            whats next and where to assign your resources.
          </p>
          <p>
            Portica is about giving you the real time view of where your world
            is, right now.
          </p>
        </FeatureText>
        <div className="column is-relative">
          <FeatureImage
            src={progressModeImg}
            alt="Progress Mode"
          ></FeatureImage>
          <SvgArrow cls="arrow-progress-top">
            <svg
              width="150"
              height="250"
              version="1.1"
              viewBox="0 0 39.688 66.146"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g transform="translate(0 -230.85)">
                <g
                  className="svg-arrow__lines"
                  strokeLinecap="round"
                  strokeWidth=".79375"
                >
                  <path d="m21.383 233.08c36.909 26.118-1.5922 52.134-18.551 61.704" />
                  <path d="m4.3467 291.38-1.515 3.4019 3.6884 0.14166" />
                </g>
              </g>
            </svg>
            <SvgArrowTxt>
              Never miss a start date with
              <br />
              smart alerts
            </SvgArrowTxt>
          </SvgArrow>
        </div>
        <FeatureText pos="right" cls="is-hidden-mobile">
          <FeatureTitle>Progress Bars</FeatureTitle>
          <p>
            Let you see how each individual design is going, and where it is
            once they start building it.
          </p>
          <p>
            Portica is about giving you a real time view of where your world is
            and what you need to do next.
          </p>
        </FeatureText>
      </Feature>

      <Feature>
        <FeatureText pos="left">
          <FeatureTitle>Project Pages</FeatureTitle>
          <p>
            Organize, document and deliver every file and create a running
            history of each project.
          </p>
          <p>
            Keep everyone on the same page and treat your clients to a great
            presentation!
          </p>
        </FeatureText>
        <div className="column is-relative">
          <FeatureImage src={projectPageImg} alt="Project Page"></FeatureImage>

          <SvgArrow cls="arrow-project-top">
            <svg
              width="150"
              height="250"
              version="1.1"
              viewBox="0 0 39.688 66.146"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g transform="translate(0 -230.85)">
                <g
                  className="svg-arrow__lines"
                  strokeLinecap="round"
                  strokeWidth=".79375"
                >
                  <path d="m21.383 233.08c36.909 26.118-1.5922 52.134-18.551 61.704" />
                  <path d="m4.3467 291.38-1.515 3.4019 3.6884 0.14166" />
                </g>
              </g>
            </svg>
            <SvgArrowTxt>
              Priority setting makes it easy to know and <br />
              show which is the current file on deck!
            </SvgArrowTxt>
          </SvgArrow>
        </div>
      </Feature>

      <Feature cls="with-mobile">
        <FeatureText pos="right" cls="is-hidden-tablet">
          <FeatureTitle>One-Click File Sharing</FeatureTitle>
          <p>
            Means you’ll never have to run back to the office to send a file
            that only stored on your hard drive!
          </p>
          <p>Easily do it from your phone or tablet!</p>
        </FeatureText>

        <div className="column is-relative">
          <FeatureImage
            src={fileSharingImg}
            alt="Easy File Sharing"
          ></FeatureImage>

          <img
            className="product-tour__feature__image__mobile is-hidden-mobile"
            src={fileSharingMobileImg}
            alt="File sharing in mobile devices"
          />

          <SvgArrow cls="arrow-file-top">
            <svg
              width="120"
              height="120"
              version="1.1"
              viewBox="0 0 120 120"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                transform="matrix(.29881 .64818 .64818 -.29881 -161.82 51.068)"
                className="svg-arrow__lines"
                strokeLinecap="round"
                strokeWidth="4.2032"
              >
                <path d="m146.14 187.67c92.504 94.8 76.618 152.02-27.352 179.09" />
                <path d="m125.6 358.33-6.8122 8.4332 10.269 3.164" />
              </g>
            </svg>
            <SvgArrowTxt>
              Portica’s embedded viewer means you don’t have to download
              <br /> or go to another tab to view your drawings!
            </SvgArrowTxt>
          </SvgArrow>
        </div>
        <FeatureText pos="right" cls="is-hidden-mobile">
          <FeatureTitle>One-Click File Sharing</FeatureTitle>
          <p>
            Means you’ll never have to run back to the office to send a file
            from your computer.
          </p>
          <p>Easily do it from your phone or tablet!</p>
        </FeatureText>
      </Feature>

      <Feature noColumns cls="version-control has-text-centered">
        <div>
          <FeatureTitle>Version Control</FeatureTitle>
          <p>
            No tool for Architects and Designers would be complete without it.
            All iterations are saved and for quick reference.
          </p>
        </div>
        <div className="is-relative version-control__image">
          <FeatureImage
            src={versionControlImg}
            alt="Version Control"
          ></FeatureImage>

          <SvgArrow cls="version-control__arrow has-text-left">
            <svg
              width="250"
              height="250"
              version="1.1"
              viewBox="0 0 250 250"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                transform="matrix(.96593 -.25882 -.25882 -.96593 62.873 318.16)"
                className="svg-arrow__lines"
              >
                <g strokeLinecap="round" strokeWidth="3">
                  <g
                    transform="rotate(-90 115.33 213.61)"
                    strokeLinecap="round"
                    strokeWidth="3"
                  >
                    <path d="m241.31 76.866c73.302 139.82-96.953 224.21-158.97 259.2" />
                    <path d="m83.143 328.35-4.4103 9.9032 10.737 0.41238" />
                  </g>
                </g>
              </g>
            </svg>
            <SvgArrowTxt>
              Date stamped and organized for a<br />
              clear picture of the design process!
              <br />
              Never again worry about what
              <br />
              happened and when.
            </SvgArrowTxt>
          </SvgArrow>
        </div>
      </Feature>
    </div>
  );
};

export default ProductTour;

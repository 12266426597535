import calendar from "../images/calendar.svg";
import comments from "../images/comments.svg";
import envelopes from "../images/envelopes.svg";
import files from "../images/files.svg";
import steps from "../images/steps.svg";
import views from "../images/views.svg";

const items = [
  {
    id: 1,
    title: "Two Dashboard Views",
    content:
      "Toggle between Snapshot and Progress View, for a 10,000-foot view of your projects.",
    image: views
  },
  {
    id: 2,
    title: "Automated Notifications",
    content:
      "Inform your clients and team members when new files or comments are added.",
    image: envelopes
  },
  {
    id: 3,
    title: "Priority Filing",
    content:
      "Highlight critical files, so your team knows where they should direct their attention.",
    image: steps
  },
  {
    id: 4,
    title: "In-Document Comments",
    content:
      "Share and collect feedback on the right file at the right time. All in mere minutes.",
    image: comments
  },
  {
    id: 5,
    title: "Start Date Reminders",
    content:
      "Set reminders about upcoming project start dates. Your future self will thank you.",
    image: calendar
  },
  {
    id: 6,
    title: "Accurate Version Control",
    content:
      "Automatically create a paper-trail of your project history, file versions and feedback.",
    image: files
  }
];

export default items;

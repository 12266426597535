import React from 'react';
import SvgArrow from '../svg-arrow';
import SvgArrowTxt from '../svg-arrow-txt';

const Hero = () => {
  const appUrl = process.env.GATSBY_APP_URL;

  return (
    <div className="portica-hero">
      <div className="portica-hero__bg">
        <h2 className="title">
          A total project platform to
          <span className="is-hidden-touch">
            <br /> move your firm forward.
          </span>
          <span className="is-hidden-desktop"> move your firm forward.</span>
        </h2>
        <p className="subtitle is-size-4">
          Portica was created by Architects for Architects and Design
          Professionals to simplify how you manage and connect the clients,
          contractors, colleagues, and deliverables that you are responsible
          for.
        </p>

        <div className="is-relative">
          <p>
            <a
              href={`${appUrl}/users/create`}
              className="button is-primary is-uppercase"
            >
              Simplify now!
            </a>
          </p>

          <SvgArrow>
            <svg
              width="125"
              height="40"
              version="1.1"
              viewBox="0 0 33.073 10.583"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g transform="translate(0 -286.42)">
                <path d="m20.538 295.8c2.8561 0.30565 5.768 0.0565 8.2169-0.70307 1.0409-0.32288 1.1717-0.40088 1.1286-0.67322-0.05137-0.32481-0.27426-0.33171-1.2139-0.0376-2.487 0.77855-5.0216 1.0139-7.9271 0.73591-1.3813-0.1321-4.1932-0.71405-5.5226-1.1429-3.7522-1.2104-7.1467-2.94-9.852-5.0201-0.95276-0.73256-0.96391-0.685 0.15944-0.68002 0.74973 3e-3 0.95669-0.082 0.93646-0.38598-0.017308-0.26018-0.20857-0.31405-1.1761-0.33116-0.47316-8e-3 -1.0918-0.0362-1.3748-0.062-0.54645-0.0501-0.69493-0.0134-0.72971 0.17832-0.011877 0.0655 0.066556 0.74231 0.17432 1.504 0.21558 1.5238 0.28376 1.69 0.63244 1.5424 0.23956-0.10145 0.24867-0.1624 0.16176-1.0817-0.039416-0.41694-0.069023-0.77265-0.06579-0.79047 0.00324-0.0178 0.17612 0.11031 0.38419 0.28472 2.8735 2.4088 7.612 4.7412 11.981 5.8972 0.78079 0.20694 3.3359 0.68525 4.0874 0.76568z" />
              </g>
            </svg>
            <SvgArrowTxt>
              You’ll be up and running in under 2 minutes. No credit card
              required.
            </SvgArrowTxt>
          </SvgArrow>

          <p className="is-size-7 is-hidden-tablet">
            <em>
              You’ll be up and running in under 2 minutes. No credit card
              required.{' '}
            </em>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;

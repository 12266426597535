import React from 'react';

const FeatureText = (props) => {
  const { children } = props;
  let pos = props.pos === 'left' ? 'left-located' : 'right-located';
  let cls = props.cls || '';

  return (
    <div className={`column product-tour__feature__text ${cls}`}>
      <div className={`product-tour__feature__text__content ${pos}`}>
        {children}
      </div>
    </div>
  );
};

export default FeatureText;

import React from 'react';
import FeatureItem from './feature-item';
import items from './text';

const FeatureItems = () => {
  return (
    <div className="features-items columns is-multiline">
      {items.map((item) => (
        <FeatureItem
          image={item.image}
          title={item.title}
          content={item.content}
          key={item.id}
        ></FeatureItem>
      ))}
    </div>
  );
};

export default FeatureItems;

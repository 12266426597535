import React from 'react';

const FeatureImage = (props) => {
  const { src, alt } = props;

  return (
    <div className="product-tour__feature__image">
      <img src={src} alt={alt} />
    </div>
  );
};

export default FeatureImage;

import React from 'react';

const Feature = (props) => {
  const cls = props.cls || '';
  const columns = props.noColumns ? '' : 'columns';
  const relative = props.noRelative ? '' : 'is-relative';

  return (
    <div className={`product-tour__feature ${columns} ${relative} ${cls}`}>
      {props.children}
    </div>
  );
};

export default Feature;

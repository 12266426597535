import React from 'react';
import FeaturesItems from './features-items';

const Features = () => {
  return (
    <div id="features" className="features">
      <h2>
        Architects and designers can manage every aspect of their deliverables,
        from file version paper-trails to client feedback and beyond.
      </h2>
      <FeaturesItems></FeaturesItems>
    </div>
  );
};

export default Features;

import React from "react";
import havenBurkee from "./images/haven_burkee.jpg";
import laddRoberts from "./images/ladd_roberts.jpg";

const testimonies = [
  {
    id: 1,
    image: havenBurkee,
    comment: (
      <div>
        <p>
          &ldquo;With email, people can be left out in communication and it can
          all be a little bit laborious. And the frustration with many document
          storage tools is just getting to the right location, having the right
          login information, and then not having to go back and ask for links
          and passwords.
        </p>
        <p>
          With Portica, everyone has access to the same information, comments
          and different design concepts. And access is easy for all the parties
          involved, which is ideal when you have several players and consultants
          involved on a project.&rdquo;
        </p>
      </div>
    ),
    name: "Haven Burkee",
    job: "Architect",
    url: "https://www.benderarchitects.com/",
    company: "Bender & Associates Architects"
  },
  {
    id: 2,
    image: laddRoberts,
    comment: (
      <div>
        <p>
          &ldquo;It used to be that I'd send a drawing by email and then next
          thing you know, you have all these pieces of feedback buried in
          emails. It was a hard go.
        </p>
        <p>
          Portica provides a clear record that the client can refer to and
          affirm completion. It helps me mark milestones as I upload project
          documents, which I then use to do my billings as a business. It helps
          keep everybody aligned as to where we are, and by the end of the
          project, the client has a portfolio of the entire design process all
          housed in one location.&rdquo;
        </p>
      </div>
    ),
    name: "Ladd Roberts",
    job: "Landscape Architect",
    url: "http://www.landwisedesign.com/",
    company: "Landwise Design"
  }
];

export default testimonies;

import React from 'react';

const Section = (props) => {
  return (
    <div className="section">
      <div className="container">{props.children}</div>
    </div>
  );
};

export default Section;

import React, { useState, useRef, useEffect } from 'react';
import useInterval from 'use-interval';
import testimonies from './text';

const Testimonies = () => {
  const testimoniesRef = useRef();
  const [current, setCurrent] = useState(1);
  const [manual, setManual] = useState(true);
  const [mouseOver, setMouseOver] = useState(false);
  const ids = testimonies.map((t) => t.id).sort();
  const lastId = ids[ids.length - 1];

  useInterval(
    () => {
      const next = current + 1;
      if (next > lastId) {
        setCurrent(1);
      } else {
        setCurrent(next);
      }
    },
    mouseOver || manual ? null : 8000
  );

  useEffect(() => {
    if (window.innerWidth >= 1024) {
      setManual(false);
    }

    testimoniesRef.current.addEventListener('mouseover', () => {
      if (mouseOver) return;
      setMouseOver(true);
    });

    testimoniesRef.current.addEventListener('mouseleave', () => {
      setMouseOver(false);
    });
  }, []);

  const goPrev = () => {
    const next = current - 1;

    if (next >= 1) {
      setCurrent(next);
      setManual(true);
    }
  };

  const goNext = () => {
    const next = current + 1;

    if (next <= lastId) {
      setCurrent(next);
      setManual(true);
    }
  };

  return (
    <div className="testimonies is-relative" ref={testimoniesRef}>
      <div className="testimonies__text__arrow arrow-left">
        {current > 1 ? (
          <button
            className="remove-button-styles is-radiusless is-shadowless is-paddingless"
            onClick={() => {
              goPrev();
            }}
          >
            &lang;
          </button>
        ) : null}
      </div>
      <div className="testimonies__text__arrow arrow-right">
        {current < lastId ? (
          <button
            className="remove-button-styles is-radiusless is-shadowless is-paddingless"
            onClick={() => {
              goNext();
            }}
          >
            &rang;
          </button>
        ) : null}
      </div>
      <div className="columns">
        <div className="column is-half testimonies__image is-hidden-mobile">
          {testimonies.map((test) => (
            <div
              className={
                current === test.id ? 'is-image is-active' : 'is-image'
              }
              key={test.id}
              style={{ backgroundImage: `url(${test.image})` }}
            ></div>
          ))}
        </div>
        <div className="column is-half testimonies__text">
          <h3 className="is-size-4 has-text-centered title">
            A clear record for everyone involved.
          </h3>
          {testimonies.map((t) => (
            <div
              className={`testimonies__text__item ${
                current === t.id ? 'is-active' : ''
              }`}
              key={t.id}
            >
              {t.comment}
              <p className="has-text-centered is-size-4">{t.name}</p>
              <p className="has-text-centered">
                {t.job} |{' '}
                <a href={t.url} target="_blank" rel="noopener noreferrer">
                  {t.company}
                </a>
              </p>
            </div>
          ))}

          <div className="testimonies__text__help is-size-7">
            Mouse over will stop the slideshow
          </div>

          <div className="testimonies__text__dots">
            <ul>
              {testimonies.map((te) => (
                <li key={te.id}>
                  <span
                    className={`dot ${current === te.id ? 'is-active' : ''}`}
                    onClick={() => setCurrent(te.id)}
                  >
                    {te.name}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonies;

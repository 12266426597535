import React from 'react';

const FeatureItem = (props) => {
  return (
    <div className="features-items__item column is-one-third has-text-centered">
      <img src={props.image} alt="Two Dashboard Views"></img>
      <p className="features-items__item__title title is-size-4">
        {props.title}
      </p>
      <p className="features-items__item__content">{props.content}</p>
    </div>
  );
};

export default FeatureItem;

import React from 'react';
import Testimonies from './testimonies/testimonies';

const Reviews = () => {
  return (
    <div className="reviews">
      <div className="is-stretch">
        <h3 className="title is-size-4 has-text-centered">
          It’s a client portal, it’s a project dashboard, it’s how you can
          really know all your projects without 17 different spreadsheets and
          apps.
        </h3>
      </div>
      <Testimonies></Testimonies>
    </div>
  );
};

export default Reviews;

import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/header';
import ProductTour from '../components/product-tour';
import Hero from '../components/hero';
import Features from '../components/features';
import { Section } from '../components/ui';
import Reviews from '../components/reviews';
import Footer from '../components/footer';
import Quote from '../components/quote';
import * as fromStorage from '../services/storage';
import { access, doLogout } from '../services/user';
import CookieConsent from '../components/cookie-consent';
import { getConsent } from '../services/consent';
import SEO from '../components/seo';

const Home = (props) => {
  let woopra = null;
  if (typeof window !== 'undefined') {
    woopra = window.woopra || null;
  }

  const [isLogged, setIsLogged] = useState(false);
  const [showCookie, setShowCookie] = useState(false);
  const [consentStatus, setConsentStatus] = useState(getConsent());

  useEffect(() => {
    if (consentStatus === 'update-here') {
      if (woopra) {
        woopra.track('home_accessed');
      }
    }
  }, [consentStatus]);

  const logout = () => {
    doLogout()
      .then(() => {
        setIsLogged(false);
      })
      .catch(() => false);
  };

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const shouldLogout = params.get('logout');

    if (shouldLogout) {
      logout();
      fromStorage.clearExp();
      fromStorage.clearUser();
    } else {
      const userStatus = access();
      Promise.resolve(userStatus)
        .then(function (value) {
          setIsLogged(value);
        })
        .catch((err) => {
          setIsLogged(false);
        });
    }
  }, []);

  const sections = {
    hero: useRef(),
    productTour: useRef(),
    reviews: useRef(),
    steps: useRef(),
    features: useRef(),
  };

  const cookieUpdate = (data) => {
    setConsentStatus(data);
    setShowCookie(false);
  };

  const toggleCookie = () => {
    setShowCookie(true);
  };

  return (
    <div className="has-navbar-fixed-top">
      <Helmet>
        <script>
          {consentStatus === 'update-here'
            ? `(function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:2930840,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
            : 'console.log("No consent")'}
        </script>
      </Helmet>
      <SEO title="Portica - All your projects in one place"></SEO>
      <div>
        <Header
          sections={sections}
          logged={isLogged}
          consent={consentStatus}
          onLogout={logout}
          toggleCookie={toggleCookie}
        ></Header>

        <div className="portica-yellow-wrapper is-hero" ref={sections.hero}>
          <div className="portica-wrapper is-widescreen">
            <Section>
              <Hero></Hero>
            </Section>
          </div>
        </div>

        <div className="portica-wrapper is-widescreen" ref={sections.reviews}>
          <Section>
            <Reviews></Reviews>
          </Section>
        </div>

        <div className="portica-wrapper is-fluid" ref={sections.productTour}>
          <Section>
            <ProductTour></ProductTour>
          </Section>
        </div>

        <div className="portica-wrapper is-widescreen" ref={sections.features}>
          <Section>
            <Quote></Quote>
          </Section>
        </div>

        <div className="portica-wrapper is-widescreen" ref={sections.features}>
          <Section>
            <Features></Features>
          </Section>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Home;
